@import "theme_overrides";
@import "print";
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-pro/css/all.min.css";

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
}

img {
  max-width: 100% !important;
}


.header-filter {
  position: relative;

  &:after, &:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
  }

  &:before {
    background: rgba(0, 0, 0, .5)
  }

  .container,
  .footer {
    z-index: 2;
    position: relative
  }
}


/* ps styles start */
.notes_ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

table th {
  text-align: center;
}

.cb {
  padding: 3px 5px;
  border: 1px solid #000;
}

.c1 {
  background: #bdd6ee;
}

.c2 {
  background: #f7caac;
}

.c3 {
  background: #ffe599;
}

.c4 {
  background: #c5e0b3;
}

.c5 {
  background: #86d0cf;
}

.c6 {
  background: #ffc000;
}

.card-body {
  padding: 15px;
}

table.table-sm {
  td {
    hyphens: auto;
    word-break: break-all;
  }
}
/* ps styles end */