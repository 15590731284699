@media print {
  .alert-info {
    display: none;
  }

  .header {
    display: none;
  }

  .footer {
    display: none;
  }

  .sf-toolbar {
    display: none !important;
  }
}