$primary: #0033eb;
$secondary: #86838C;
$light: #FFF;
$dark: #5b5277;
$info: #17a2b8;

//$success: #a337e4;
//$warning: #020003;
//$danger: #230734;

.fad {
  --fa-secondary-opacity: 1.0;
  --fa-primary-opacity: 0.3;
  --fa-primary-color: #0033eb !important;
  --fa-secondary-color: #0033eb !important;
}